@tailwind base;
@tailwind components;
@tailwind utilities;
@media (max-width: 768px) {
  .hide-text {
    display: none;
  }
  .bg-blue-500 {
    font-size: 10px; /* Atur ukuran teks tombol */
    padding: 8px 16px; /* Atur padding tombol */
  }
}

/* Description: Master CSS file */

/*****************************************
Table Of Contents:
- General Styles
- Navigation
- Header
- Features
- Details Lightbox
- Statistics
- Testimonials
- Pricing
- Conclusion
- Footer
- Copyright
- Back To Top Button
- Media Queries
******************************************/

/*****************************************
Colors:
- Backgrounds - light gray #f1f9fc
- Buttons, icons - purple #594cda
- Buttons, icons - red #eb427e
- Headings text - black #252c38
- Body text - dark gray #6b747b
******************************************/

/**************************/
/*     General Styles     */
/**************************/

body,
p {
  color: #6b747b;
  font: 400 1rem/1.625rem "Open Sans", sans-serif;
}

h1 {
  color: #252c38;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.125rem;
  letter-spacing: -0.5px;
}

h2 {
  color: #252c38;
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.625rem;
  letter-spacing: -0.4px;
}

h3 {
  color: #252c38;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2.25rem;
  letter-spacing: -0.3px;
}

h4 {
  color: #252c38;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.2px;
}

h5 {
  color: #252c38;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.625rem;
}

h6 {
  color: #252c38;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
}

.h1-large {
  font-size: 2.875rem;
  line-height: 3.5rem;
}

.p-large {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.p-small {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.bg-gray {
  background-color: #f1f9fc;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.btn-solid-reg {
  display: inline-block;
  padding: 1.375rem 2.25rem 1.375rem 2.25rem;
  border: 1px solid #594cda;
  border-radius: 32px;
  background-color: #594cda;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-solid-reg:hover {
  border: 1px solid #594cda;
  background-color: transparent;
  color: #594cda; /* needs to stay here because of the color property of a tag */
  text-decoration: none;
}

.btn-solid-lg {
  display: inline-block;
  padding: 1.625rem 2.75rem 1.625rem 2.75rem;
  border: 1px solid #319795;
  border-radius: 32px;
  background-color: #319795;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
  margin-right: 0.25rem;
  margin-bottom: 1.25rem;
  margin-left: 0.25rem;
}

.btn-solid-lg:hover {
  border: 1px solid #594cda;
  background-color: transparent;
  color: #594cda; /* needs to stay here because of the color property of a tag */
  text-decoration: none;
}

.btn-solid-lg .fab {
  margin-right: 0.5rem;
  font-size: 1.25rem;
  line-height: 0;
  vertical-align: top;
}

.btn-solid-lg .fab.fa-google-play {
  font-size: 1rem;
}

.btn-solid-lg.secondary {
  border: 1px solid #eb427e;
  background-color: #eb427e;
}

.btn-solid-lg.secondary:hover {
  border: 1px solid #eb427e;
  background: transparent;
  color: #eb427e; /* needs to stay here because of the color property of a tag */
}

.btn-outline-reg {
  display: inline-block;
  padding: 1.375rem 2.25rem 1.375rem 2.25rem;
  border: 1px solid #252c38;
  border-radius: 32px;
  background-color: transparent;
  color: #252c38;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-outline-reg:hover {
  background-color: #252c38;
  color: #ffffff;
  text-decoration: none;
}

.btn-outline-lg {
  display: inline-block;
  padding: 1.625rem 2.75rem 1.625rem 2.75rem;
  border: 1px solid #252c38;
  border-radius: 32px;
  background-color: transparent;
  color: #252c38;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-outline-lg:hover {
  background-color: #252c38;
  color: #ffffff;
  text-decoration: none;
}

.btn-outline-sm {
  display: inline-block;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border: 1px solid #252c38;
  border-radius: 32px;
  background-color: transparent;
  color: #252c38;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-outline-sm:hover {
  background-color: #252c38;
  color: #ffffff;
  text-decoration: none;
}

.list .fas {
  color: #594cda;
  font-size: 0.75rem;
  line-height: 1.625rem;
}

.list div {
  flex: 1 1 0%;
  margin-left: 0.375rem;
}

.form-group {
  position: relative;
  margin-bottom: 1.25rem;
}

.label-control {
  position: absolute;
  top: 0.875rem;
  left: 1.875rem;
  color: #7d838a;
  opacity: 1;
  font-size: 0.875rem;
  line-height: 1.5rem;
  cursor: text;
  transition: all 0.2s ease;
}

.form-control-input:focus + .label-control,
.form-control-input.notEmpty + .label-control,
.form-control-textarea:focus + .label-control,
.form-control-textarea.notEmpty + .label-control {
  top: 0.125rem;
  color: #6b747b;
  opacity: 1;
  font-size: 0.75rem;
  font-weight: 700;
}

.form-control-input,
.form-control-select {
  display: block; /* needed for proper display of the label in Firefox, IE, Edge */
  width: 100%;
  padding-top: 1.125rem;
  padding-bottom: 0.125rem;
  padding-left: 1.8125rem;
  border: 1px solid #d0d5e2;
  border-radius: 25px;
  background-color: #ffffff;
  color: #6b747b;
  font-size: 0.875rem;
  line-height: 1.875rem;
  transition: all 0.2s;
  -webkit-appearance: none; /* removes inner shadow on form inputs on ios safari */
}

.form-control-select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 3.25rem;
  color: #7d838a;
}

select {
  /* you should keep these first rules in place to maintain cross-browser behavior */
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url("https://cdn-icons-png.flaticon.com/16/25/25623.png");
  background-position: 96% 50%;
  background-repeat: no-repeat;
  outline: none;
}

.form-control-textarea {
  display: block; /* used to eliminate a bottom gap difference between Chrome and IE/FF */
  width: 100%;
  height: 14rem; /* used instead of html rows to normalize height between Chrome and IE/FF */
  padding-top: 1.5rem;
  padding-left: 1.3125rem;
  border: 1px solid #d0d5e2;
  border-radius: 4px;
  background-color: #ffffff;
  color: #6b747b;
  font-size: 0.875rem;
  line-height: 1.5rem;
  transition: all 0.2s;
}

.form-control-input:focus,
.form-control-select:focus,
.form-control-textarea:focus {
  border: 1px solid #a1a1a1;
  outline: none; /* Removes blue border on focus */
}

.form-control-input:hover,
.form-control-select:hover,
.form-control-textarea:hover {
  border: 1px solid #a1a1a1;
}

.checkbox {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

input[type="checkbox"] {
  vertical-align: -10%;
  margin-right: 0.5rem;
}

.form-control-submit-button {
  display: inline-block;
  width: 100%;
  height: 3.25rem;
  border: 1px solid #594cda;
  border-radius: 32px;
  background-color: #594cda;
  color: #252c38;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0;
  cursor: pointer;
  transition: all 0.2s;
}

.form-control-submit-button:hover {
  border: 1px solid #252c38;
  background-color: transparent;
  color: #252c38;
}

/* Fade-move Animation For Details Lightbox - Magnific Popup */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
  -ms-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
  transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(37.5rem) rotateX(0);
  -ms-transform: translateY(0) perspective(37.5rem) rotateX(0);
  transform: translateY(0) perspective(37.5rem) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
  -ms-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
  transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
}

/* dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}
/* end of fade-move animation for details lightbox - magnific popup */

/**********************/
/*     Navigation     */
/**********************/

.navbar {
  position: relative;
  background-color: #f1f9fc;
  padding: 0.5rem 1rem;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.75rem;
  transition: all 0.2s ease;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar-toggler-icon {
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.navbar-collapse {
  flex-basis: 100%;
}

.offcanvas-collapse {
  position: fixed;
  top: 3.25rem; /* adjusts the height between the top of the page and the offcanvas menu */
  bottom: 0;
  left: 100%;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  overflow-y: auto;
  visibility: hidden;
  background-color: #f1f9fc;
  transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}

.offcanvas-collapse.open {
  visibility: visible;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.nav-link {
  display: block;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  color: #6b747b;
  text-decoration: none;
  line-height: 0.875rem;
  transition: all 0.2s ease;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown.show > a,
.nav-link:hover,
.nav-link.active {
  color: #ff6e84;
  text-decoration: none;
}

.dropdown-menu {
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #212529;
  list-style: none;
  background-color: #f1f9fc;
  background-clip: padding-box;
  border-radius: 0.25rem;
  animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dropdown-menu.show {
  display: block;
  top: 90%;
  left: auto;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  color: #6b747b;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-decoration: none;
}

.dropdown-item:hover,
.dropdown-item:focus {
  text-decoration: none;
  background-color: #f1f9fc;
  color: #ff6e84;
}

.dropdown-divider {
  overflow: hidden;
  width: 100%;
  height: 1px;
  margin: 0.5rem auto 0.5rem auto;
  background-color: #d4dce2;
}
/* end of dropdown menu */

/*****************/
/*    Header     */
/*****************/
.header {
  background: linear-gradient(rgba(197, 234, 249, 1), rgba(255, 255, 255, 1));
}

/********************/
/*     Features     */
/********************/
.cards-1 {
  padding-top: 4rem;
  padding-bottom: 1.5rem;
  text-align: center;
}

.cards-1 .card {
  margin-bottom: 3.5rem;
  padding: 3.125rem 1rem 2.125rem 1rem;
  border: none;
  border-radius: 16px;
  background-color: #f1f9fc;
}

.cards-1 .card-image {
  margin-bottom: 1.5rem;
}

.cards-1 .card-image img {
  width: 70px;
  height: 70px;
  margin-right: auto;
  margin-left: auto;
}

.cards-1 .card-body {
  padding: 0;
}

.cards-1 .card-title {
  margin-bottom: 0.375rem;
}

/****************************/
/*     Details Lightbox     */
/****************************/
.lightbox-basic {
  position: relative;
  max-width: 1150px;
  margin: 2.5rem auto;
  padding: 3rem 1rem;
  background-color: #ffffff;
  text-align: left;
}

/* Action Button */
.lightbox-basic .btn-solid-reg.mfp-close {
  position: relative;
  width: auto;
  height: auto;
  color: #ffffff;
  opacity: 1;
  font-weight: 600;
  font-family: "Open Sans";
}

.lightbox-basic .btn-solid-reg.mfp-close:hover {
  color: #594cda;
}
/* end of action Button */

/* Back Button */
.lightbox-basic .btn-outline-reg.mfp-close.as-button {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 0.375rem;
  padding: 1.375rem 2.25rem 1.375rem 2.25rem;
  border: 1px solid #252c38;
  background-color: transparent;
  color: #252c38;
  opacity: 1;
  font-family: "Open Sans";
}

.lightbox-basic .btn-outline-reg.mfp-close.as-button:hover {
  background-color: #252c38;
  color: #ffffff;
}
/* end of back button */

/* Close X Button */
.lightbox-basic button.mfp-close.x-button {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 44px;
  height: 44px;
  color: #555555;
}
/* end of close x button */

/**********************/
/*     Statistics     */
/**********************/
.counter {
  padding-top: 2rem;
  padding-bottom: 4.5rem;
  text-align: center;
}

.counter #counter {
  margin-bottom: 0.75rem;
}

.counter #counter .cell {
  display: inline-block;
  width: 120px;
  margin-right: 1.75rem;
  margin-bottom: 3.5rem;
  margin-left: 1.75rem;
  vertical-align: top;
}

.counter #counter .counter-value {
  color: #252c38;
  font-weight: 700;
  font-size: 3.25rem;
  line-height: 3.75rem;
  vertical-align: middle;
}

.counter #counter .counter-info {
  margin-bottom: 0;
  font-size: 0.875rem;
  vertical-align: middle;
}

/************************/
/*     Testimonials     */
/************************/

.slider-1 .slider-container {
  position: relative;
}

.slider-1 .swiper-container {
  position: static;
  width: 86%;
  text-align: center;
}

.slider-1 .swiper-button-prev:focus,
.slider-1 .swiper-button-next:focus {
  /* even if you can't see it chrome you can see it on mobile device */
  outline: none;
}

.slider-1 .swiper-button-prev {
  left: -14px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23707375'%2F%3E%3C%2Fsvg%3E");
  background-size: 18px 28px;
}

.slider-1 .swiper-button-next {
  right: -14px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23707375'%2F%3E%3C%2Fsvg%3E");
  background-size: 18px 28px;
}

.slider-1 .card {
  position: relative;
  border: none;
  background-color: transparent;
}

.slider-1 .card-image {
  width: 80px;
  height: 80px;
  margin-right: auto;
  margin-bottom: 1.25rem;
  margin-left: auto;
  border-radius: 50%;
}

.slider-1 .testimonial-author {
  margin-bottom: 0;
  color: #252c38;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

/*******************/
/*     Pricing     */
/*******************/
.cards-2 {
  position: relative;
  padding-top: 8rem;
  background: linear-gradient(rgba(50, 60, 70, 0.9), rgba(50, 60, 70, 0.9)),
    url("https://static.vecteezy.com/system/resources/thumbnails/016/622/849/small/red-paper-tag-label-ripped-torn-cut-edges-isolated-background-png.png")
      center center no-repeat;
  background-size: cover;
  text-align: center;
}

.cards-2 .card {
  position: relative;
  display: block;
  background-color: #ffffff;
  max-width: 330px;
  margin-right: auto;
  margin-bottom: 6rem;
  margin-left: auto;
  border: 1px solid #bcc4ca;
  border-radius: 8px;
}

.cards-2 .card .card-body {
  padding: 3rem 1.75rem 2.25rem 1.75rem;
}

.cards-2 .card .card-title {
  margin-bottom: 1rem;
  color: #eb427e;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  text-align: center;
}

.cards-2 .card p {
  margin-bottom: 1.25rem;
  text-align: left;
}

.cards-2 .card .value {
  color: #252c38;
  font-weight: 600;
  font-size: 5rem;
  line-height: 5rem;
  text-align: center;
}

.cards-2 .card .currency {
  margin-right: 0.375rem;
  color: #252c38;
  font-size: 2rem;
  vertical-align: 80%;
}

.cards-2 .card .frequency {
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  text-align: center;
}

.cards-2 .card .button-wrapper {
  position: absolute;
  right: 0;
  bottom: -1.5rem;
  left: 0;
  text-align: center;
}

.cards-2 .card .btn-solid-reg:hover {
  background-color: #ffffff;
}

/* Best Value Label */
.cards-2 .card .label {
  position: absolute;
  top: 0;
  right: 0;
  width: 10.625rem;
  height: 10.625rem;
  overflow: hidden;
}

.cards-2 .card .label .best-value {
  position: relative;
  width: 13.75rem;
  padding: 0.3125rem 0 0.3125rem 4.125rem;
  background-color: #eb427e;
  color: #ffffff;
  -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
  -ms-transform: rotate(45deg) translate3d(0, 0, 0);
  transform: rotate(45deg) translate3d(0, 0, 0);
}
/* end of best value label */

/**********************/
/*     Conclusion     */
/**********************/
.basic-5 {
  padding-top: 4.5rem;
  padding-bottom: 7.5rem;
  background: url("https://png.pngtree.com/png-vector/20220603/ourmid/pngtree-conclusion-opinion-rubber-rectangular-vector-png-image_18401368.png")
    center center no-repeat;
  background-size: cover;
  text-align: center;
}

/******************/
/*     Footer     */
/******************/
.footer {
  padding-top: 6rem;
  padding-bottom: 3rem;
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(197, 234, 249, 1));
  text-align: center;
}

.footer a {
  text-decoration: none;
}

.footer .fa-stack {
  width: 2em;
  margin-bottom: 1.25rem;
  margin-right: 0.375rem;
  font-size: 1.5rem;
}

.footer .fa-stack .fa-stack-1x {
  color: #252c38;
  transition: all 0.2s ease;
}

.footer .fa-stack .fa-stack-2x {
  color: #ffffff;
  transition: all 0.2s ease;
}

.footer .fa-stack:hover .fa-stack-1x {
  color: #ffffff;
}

.footer .fa-stack:hover .fa-stack-2x {
  color: #252c38;
}

/*********************/
/*     Copyright     */
/*********************/
.copyright {
  padding-top: 1.5rem;
  background-color: #c5eaf9;
  text-align: center;
}

/******************************/
/*     Back To Top Button     */
/******************************/
a.back-to-top {
  position: fixed;
  z-index: 999;
  right: 12px;
  bottom: 12px;
  display: none;
  width: 42px;
  height: 42px;
  border-radius: 30px;
  background: #343e48 url("https://cdn-icons-png.flaticon.com/24/25/25637.png")
    no-repeat center 47%;
  background-size: 18px 18px;
  text-indent: -9999px;
}

a:hover.back-to-top {
  background-color: #000000;
}

/***********************/
/*     Extra Pages     */
/***********************/
.ex-header {
  padding-top: 8.5rem;
  padding-bottom: 4rem;
  background-color: #f1f9fc;
}

.ex-basic-1 .list-unstyled .fas {
  font-size: 0.375rem;
  line-height: 1.625rem;
}

.ex-basic-1 .text-box {
  padding: 1.25rem 1.25rem 0.5rem 1.25rem;
  background-color: #f1f9fc;
}

.ex-cards-1 .card {
  border: none;
  background-color: transparent;
}

.ex-cards-1 .card .fa-stack {
  width: 2em;
  font-size: 1.125rem;
}

.ex-cards-1 .card .fa-stack-2x {
  color: #ff6e84;
}

.ex-cards-1 .card .fa-stack-1x {
  color: #ffffff;
  font-weight: 700;
  line-height: 2.125rem;
}

/*************************/
/*     Media Queries     */
/*************************/

/* Min-width 768px */
@media (min-width: 768px) {
  /* Extra Pages */
  .ex-basic-1 .text-box {
    padding: 1.75rem 2rem 0.875rem 2rem;
  }
  /* end of extra pages */
}
/* end of min-width 768px */

/* Min-width 1024px */
@media (min-width: 1024px) {
  /* General Styles */

  .btn-solid-lg {
    margin-right: 0.5rem;
    margin-left: 0;
  }

  /* end of general styles */

  /* Navigation */
  .navbar {
    background-color: transparent;
    flex-wrap: nowrap;
    justify-content: start;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1.75rem;
  }

  .navbar-collapse {
    flex-basis: auto;
  }

  .navbar.top-nav-collapse {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #f1f9fc;
  }

  .offcanvas-collapse {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    width: auto;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    overflow-y: visible;
    visibility: visible;
  }

  .offcanvas-collapse.open {
    -webkit-transform: none;
    transform: none;
  }

  .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }

  .dropdown-menu {
    position: absolute;
    margin-top: 0.25rem;
    box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.05);
  }

  .dropdown-divider {
    width: 90%;
  }

  /* end of navigation */

  /* Details Lightbox */
  .lightbox-basic {
    padding: 3rem 3rem;
  }
  /* end of details lightbox */

  /* Features */
  .cards-1 .card {
    display: inline-block;
    width: 306px;
    vertical-align: top;
  }

  .cards-1 .card:nth-of-type(3n + 2) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  /* end of features */

  /* Statistics */
  .counter {
    padding-top: 5rem;
  }
  /* end of statistics */

  /* Testimonials */
  .slider-1 .swiper-container {
    width: 92%;
  }

  .slider-1 .swiper-button-prev {
    left: -16px;
    width: 22px;
    background-size: 22px 34px;
  }

  .slider-1 .swiper-button-next {
    right: -16px;
    width: 22px;
    background-size: 22px 34px;
  }
  /* end of testimonials */

  /* Pricing */
  .cards-2 .card {
    display: inline-block;
    max-width: 100%;
    width: 312px;
    vertical-align: top;
  }

  .cards-2 .card:nth-of-type(3n + 2) {
    margin-right: 0.375rem;
    margin-left: 0.375rem;
  }
  /* end of pricing */

  /* Conclusion */
  .basic-5 {
    text-align: left;
  }
  /* end of conclusion */

  /* Copyright */
  .copyright {
    text-align: left;
  }

  .copyright .list-unstyled li {
    display: inline-block;
    margin-right: 1rem;
  }

  .copyright .statement {
    text-align: right;
  }
  /* end of copyright */

  /* Extra Pages */
  .ex-cards-1 .card {
    display: inline-block;
    width: 306px;
    vertical-align: top;
  }

  .ex-cards-1 .card:nth-of-type(3n + 2) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  /* end of extra pages */
}
/* end of min-width 1024px */

/* Min-width 1280px */
@media (min-width: 1280px) {
  /* General Styles */
  .h1-large {
    font-size: 3.125rem;
    line-height: 3.75rem;
  }

  .container {
    max-width: 72rem;
  }
  /* end of general styles */

  /* Features */
  .cards-1 .card {
    width: 342px;
    padding-right: 2.875rem;
    padding-left: 2.875rem;
  }

  .cards-1 .card:nth-of-type(3n + 2) {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  /* end of features */

  /* Statistics */
  .counter #counter .cell {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .counter #counter .counter-value {
    font-size: 3.75rem;
    line-height: 4.25rem;
  }
  /* end of statistics */

  /* Pricing */
  .cards-2 .card {
    width: 335px;
  }

  .cards-2 .card:nth-of-type(3n + 2) {
    margin-right: 2.25rem;
    margin-left: 2.25rem;
  }

  .cards-2 .card .card-body {
    padding-right: 2.25rem;
    padding-left: 2.25rem;
  }
  /* end of pricing */

  /* Extra Pages */
  .ex-cards-1 .card {
    width: 328px;
  }

  .ex-cards-1 .card:nth-of-type(3n + 2) {
    margin-right: 2.875rem;
    margin-left: 2.875rem;
  }
  /* end of extra pages */
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}
.sidebar-content {
  margin-top: 10px; /* Jarak antara tombol penutup dan konten sidebar */
  padding: 10px; /* Ruang dalam konten sidebar */
  background-color: #f0f0f0; /* Warna latar belakang konten sidebar */
}

/* end of min-width 1200px */
